import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { connect } from "react-redux";
import { Button, Flex, Tabs, Tag, Select, Checkbox, Popconfirm, message } from 'antd';
import {
  CloseCircleOutlined,
  DeleteOutlined,
  LoginOutlined,
  WhatsAppOutlined,
  TrophyOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useNavigate } from 'react-router-dom';

import styles from './ticket.module.css';
import Header from '../Base/Header';
import TicketTable from './TicketTable';
import RemarkModal from "./RemarkModal";

import { closeTicket, deleteTicket, getAllTicketStatusCount, getPlans, getSalesPerson, getSupportPerson, languageOptions, loginToLab, updateSupportPerson } from './helper/apiService';
import SupportTicketModal from "./SupportTicketModal";

const statusOptions = [
  {
    value: "PENDING",
    label: "Pending"
  },
  {
    value: "IN_PROGRESS",
    label: "In Progress"
  },
  {
    value: "BUG",
    label: "Bug"
  },
]

const getTabItems = (columns, closeTicketColumn, isChanged, downloadData, loading, setLoading) => [
  {
    key: '1',
    label: 'Pending',
    status: 'pending',
    columns: columns,
  },
  {
    key: '2',
    label: 'In process',
    status: 'in_progress',
    columns: columns,
  },
  {
    key: '3',
    label: 'Bug/Requirement',
    status: 'bug',
    columns: columns,
  },
  {
    key: '4',
    label: 'Closed',
    status: 'closed',
    columns: closeTicketColumn,
  },
];

const Ticket = ({ userRole, userId, userMainRole }) => {
  const navigate = useNavigate();
  const [supportPersonOptions, setSupportPersonOptions] = useState([]);
  const [salesPersonOptions, setSalesPersonOptions] = useState([]);
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isStatusChanged, setIsStatusChanged] = useState(false);
  const [statusCount, setStatusCount] = useState(null);
  const [openTicketModal, setOpenTicketModal] = useState(false);

  useEffect(() => {
    handleGetSupportPerson();
    handleGetSalesPerson();
    handleGetPlans();
    handleGetStatus();
  }, []);

  useEffect(() => {
    if (isStatusChanged) {
      handleGetStatus();
    }
  }, [isStatusChanged]);

  const handleGetSupportPerson = () => getSupportPerson(setSupportPersonOptions);
  const handleGetSalesPerson = () => getSalesPerson(setSalesPersonOptions);
  const handleGetStatus = () => getAllTicketStatusCount(setStatusCount, setIsStatusChanged);

  const handleLoginTolab = (data) => loginToLab(data);
  const handleDeleteTicket = (id) => deleteTicket(id, setIsChanged);
  const handleCloseTicket = (id) => closeTicket(id, userId, setIsChanged, setIsStatusChanged);
  const handleUpdateSupportPerson = (id, value) => updateSupportPerson({
    id, data: value
  }, setLoading, setIsChanged, setIsStatusChanged)


  const handleGetPlans = () => getPlans(setPlans);
  const handleWhatsApp = (labName, contact, supportPerson) => {
    if (contact) {

      let message = `Hi ${labName}, %0A%0AI'm ${supportPerson} from Flabs support team. I'm reaching out regarding the ticket you raised.`;
      window.open(`https://api.whatsapp.com/send?phone=${contact}&text=${message}`, "_blank");
    } else {
      message.error("Contact is not correct")
    }
  };

  const columns = [
    {
      title: "Lab Details",
      dataIndex: "labDetails",
      width: "10%",
      key: "labDetails",
      render: (text, record) => (
        <div>
          <p>{record?.ticketID}</p>
          <p style={{ fontWeight: "500" }}>{text?.labName}</p>
          {text?.labPhone && <p style={{ whiteSpace: "nowrap" }}>{text?.labPhone}</p>}
          <p>{text?.ownerName}</p>
          <Flex style={{ marginTop: 5 }}>
            <Tag color="processing" style={{ fontWeight: "500" }}>
              {text?.tag}
            </Tag>
            <Checkbox style={{ marginLeft: "5px" }} checked={record?.urgent} onChange={(e) => handleUpdateSupportPerson(record?._id, { urgent: e.target.checked })} />
          </Flex>
        </div>
      ),
    },
    {
      title: "Query",
      dataIndex: "query",
      width: "25%",
      key: "query",
    },
    {
      title: (<span style={{ whiteSpace: "nowrap" }}>Requested At</span>),
      dataIndex: "requestedAt",
      width: "10%",
      defaultSortOrder: "descend",
      key: "requestedAt",
      render: (text) => (
        <p>
          {dayjs(text).format("DD-MM-YYYY")} <br />
          {dayjs(text).format("hh:mm A")}
        </p>
      ),
      sorter: (a, b) => {
        // First check if urgent is different, prioritize urgent tickets
        if (a.urgent !== b.urgent) {
          return a.urgent - b.urgent;
        }
        // If urgent is the same, sort by requestedAt date
        return new Date(a.requestedAt) - new Date(b.requestedAt);
      },
    },
    {
      title: (<span style={{ whiteSpace: "nowrap" }}>Sales Person</span>),
      dataIndex: "salesPerson",
      width: "10%",
      key: "salesPerson",
    },
    {
      title: "Support Person",
      dataIndex: "supportPerson",
      width: "10%",
      key: "supportPerson",
      render: (text, record) => (
        (userRole === "admin" || (userRole === "user" && userMainRole === "support-admin")) ?
          <Select options={supportPersonOptions} value={text} style={{ width: "100%" }} placeholder="Support person" onChange={(value) => handleUpdateSupportPerson(record?._id, { supportPerson: value })} />
          : text)
    },
    {
      title: "Language",
      dataIndex: "language",
      key: "language",
      render: (text, record) => (<Select value={text} options={languageOptions()} style={{ width: "100%" }} placeholder="Language" onChange={(value) => handleUpdateSupportPerson(record?._id, { language: value })} />),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (<Select value={text} style={{ width: "106px" }} placeholder="Status" options={statusOptions} onChange={(value) => handleUpdateSupportPerson(record?._id, { status: value })} />),
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      render: (text, record) => {
        const countryCode = record?.countryCode || "+91";
        const contact = record?.contact ? record?.contact : record?.lab?.labAdminID?.contact;
        return (
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <RemarkModal ticketID={record?._id} remark={record?.remarks} supportPerson={record?.supportPerson} setIsChange={setIsChanged} />
            <Button
              style={{
                border: "1px solid #25D366",
                fontSize: "16px",
                background: "#25D366",
                height: 32,
                width: 32,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => handleWhatsApp(record?.labDetails?.labName,
                contact,
                record?.supportPerson)}
            >
              <WhatsAppOutlined style={{ color: "white" }} />
            </Button>
            <Popconfirm
              title="Close this ticket?"
              description="Are you sure to close this ticket?"
              onConfirm={() => handleCloseTicket(record?._id)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                style={{
                  height: 32,
                  width: 32,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CloseCircleOutlined />
              </Button>
            </Popconfirm>
            <Button
              type="primary"
              onClick={() => handleLoginTolab(text?.labAdminContact)}
              style={{
                height: 32,
                width: 32,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoginOutlined />
            </Button>
            {userRole === "admin" && (
              <Popconfirm
                title="Delete this ticket?"
                description="Are you sure to delete this ticket?"
                onConfirm={() => handleDeleteTicket(text?.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  style={{
                    background: "var(--Dust-Red-1, #FFF1F0)",
                    color: "#F5222D",
                    border: "1px solid #FFA39E",
                    height: 32,
                    width: 32,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <DeleteOutlined style={{ color: "#F5222D" }} />
                </Button>
              </Popconfirm>
            )}
          </div >
        )
      },
    },
  ];

  const closeTicketColumn = [
    {
      title: "Lab Details",
      dataIndex: "labDetails",
      width: "15%",
      key: "labDetails",
      render: (text, record) => (
        <div>
          <p>{record?.ticketID}</p>
          <p style={{ fontWeight: "500" }}>{text?.labName}</p>
          <p>{text?.labPhone}</p>
          <p>{text?.ownerName}</p>
          <Flex style={{ marginTop: 5 }}>
            <Tag color="processing" style={{ fontWeight: "500" }}>
              {text?.tag}
            </Tag>
            <Checkbox style={{ marginLeft: "5px" }} checked={record?.urgent} onChange={(e) => handleUpdateSupportPerson(record?._id, { urgent: e.target.checked })} />
          </Flex>
        </div>
      ),
    },
    {
      title: "Query",
      dataIndex: "query",
      width: "25%",
      key: "query",
    },
    {
      title: (<span style={{ whiteSpace: "nowrap" }}>Requested At</span>),
      dataIndex: "requestedAt",
      width: "10%",
      defaultSortOrder: "descend",
      key: "requestedAt",
      render: (text) => (
        <p>
          {dayjs(text).format("DD-MM-YYYY")} <br />
          {dayjs(text).format("hh:mm A")}
        </p>
      ),
      sorter: (a, b) => {
        // First check if urgent is different, prioritize urgent tickets
        if (a.urgent !== b.urgent) {
          return a.urgent - b.urgent;
        }
        // If urgent is the same, sort by requestedAt date
        return new Date(a.requestedAt) - new Date(b.requestedAt);
      },
    },
    {
      title: (<span style={{ whiteSpace: "nowrap" }}>Closed At</span>),
      dataIndex: "closedAt",
      width: "10%",
      defaultSortOrder: "descend",
      key: "closedAt",
      render: (text) => (
        <p>
          {dayjs(text).format("DD-MM-YYYY")} <br />
          {dayjs(text).format("hh:mm A")}
        </p>
      ),
      sorter: (a, b) => {
        return new Date(a.closedAt) - new Date(b.closedAt);
      },
    },
    {
      title: "TAT & Pick time",
      dataIndex: "tatRating",
      width: "10%",
      key: "tatRating",
    },
    {
      title: "Sales Person",
      dataIndex: "salesPerson",
      width: "10%",
      key: "salesPerson",
    },
    {
      title: "Support Person",
      dataIndex: "supportPerson",
      width: "10%",
      key: "supportPerson",
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      width: "10%",
      render: (text, { labDetails, supportPerson, remarks, _id }) => (
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <RemarkModal ticketID={_id} remark={remarks} supportPerson={supportPerson} setIsChange={setIsChanged} />
          <Button
            style={{
              height: 32,
              width: 32,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid #25D366",
              fontSize: "16px",
              background: "#25D366",

            }}
            onClick={() => handleWhatsApp(labDetails?.labName, labDetails?.contact, supportPerson)}
          >
            <WhatsAppOutlined style={{ color: "white" }} />
          </Button>
          <Button
            type="primary"
            onClick={() => handleLoginTolab(text?.labAdminContact)}
            style={{
              height: 32,
              width: 32,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoginOutlined />
          </Button>
        </div >
      ),
    },
  ];

  const tabItems = getTabItems(columns, closeTicketColumn, isChanged, loading, setLoading).map(item => ({
    key: item.key,
    label: item.label,
    children: (
      <TicketTable
        columns={item.columns}
        isChanged={isChanged}
        updateLoading={loading}
        setLoading={setLoading}
        status={item.status}
        plans={plans}
        salesPersonOptions={salesPersonOptions}
        supportPersonOptions={supportPersonOptions}
        setStatusCount={setStatusCount}
      />
    ),
  }));

  return (
    <>
      <Header />
      <section className={styles['blog-section-container']}
        style={{
          position: 'relative',
        }}>
        <Tabs
          defaultActiveKey="1"
          indicatorSize={(origin) => origin + 20}
          items={tabItems}
          tabBarStyle={{
            width: "400px",
          }}
          destroyInactiveTabPane
        />
        <Flex
          style={{
            position: 'absolute',
            top: '10px',
            left: '30%',
          }}
          gap={10}
          justify='space-between'
        >
          <Flex gap={5}>
            <Tag
              color="volcano"
              bordered={false}
              style={{
                padding: "2px 8px",
                fontWeight: 600,
                fontSize: 14
              }}>Pending: {statusCount?.totalPending}</Tag>
            <Tag
              color="red"
              bordered={false}
              style={{
                padding: "2px 8px",
                fontWeight: 600,
                fontSize: 14
              }}>Urgent: {statusCount?.totalUrgent}</Tag>
            <Tag
              color="blue"
              bordered={false}
              style={{
                padding: "2px 8px",
                fontWeight: 600,
                fontSize: 14
              }}>In process: {statusCount?.totalInProgress}</Tag>
            <Tag
              color="gold"
              bordered={false}
              style={{
                padding: "2px 8px",
                fontWeight: 600,
                fontSize: 14
              }}>Bug/Requirement: {statusCount?.totalBug}</Tag>
            <Tag
              color="green"
              bordered={false}
              style={{
                padding: "2px 8px",
                fontWeight: 600,
                fontSize: 14
              }}>Closed: {statusCount?.totalClosed}</Tag>
          </Flex>
        </Flex>
        <Flex
          style={{
            position: 'absolute',
            right: '0',
            top: '10px',
          }}
          gap={8}
        >
          <Button onClick={() => setOpenTicketModal(true)}><PlusOutlined />Ticket</Button>
          <Button type='primary' onClick={() => navigate("/leaderboard")}>
            <TrophyOutlined /> Leaderboard
          </Button>
        </Flex>
      </section>
      {openTicketModal && <SupportTicketModal
        openModal={openTicketModal}
        setOpenModal={setOpenTicketModal}
        supportPersons={supportPersonOptions}
        setIsChanged={setIsChanged}
        setIsStatusChanged={setIsStatusChanged}
      />
      }

    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userRole: state.auth.userRole,
    userId: state.auth.id,
    name: state.auth.name,
    userMainRole: state.auth.userMainRole,
  };
};

export default connect(mapStateToProps)(Ticket);
