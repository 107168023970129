import React, { useEffect, useState } from "react";
import { Table, Button, Card, Flex, Tag, Popconfirm } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";

import axios from "axios";
import { connect } from "react-redux";
import dayjs from "dayjs";
import AddSuggestedFeatureModal from "./AddSuggestedFeatureModal";

const SuggestedFeatureTable = ({ authToken }) => {
  const [loading, setLoading] = useState(false);
  const [suggestedFeatures, setSuggestedFeatures] = useState([]);
  const [suggestedFeatureModal, setSuggestedFeatureModal] = useState(false);
  const getSuggestedFeature = async () => {
    setLoading(true);
    const { data } = await axios.get(
      `/suggestedFeature/getSuggestedFeatureSuggestions`,
      {
        headers: {
          Authorization: `Custom ${authToken}`,
        },
      },
    );

    setSuggestedFeatures(
      data.map((item, index) => ({
        ...item,
        srNo: index + 1,
        name: item.name,
        date: item.date,
      })),
    );
    setLoading(false);
  };

  useEffect(() => {
    getSuggestedFeature();
  }, []);

  const handleDelete = async (id) => {
    console.log("id", id);
    await axios.post(
      `/suggestedFeature/deleteSuggestedFeatureSuggestion`,
      { id },
      {
        headers: {
          Authorization: `Custom ${authToken}`,
        },
      },
    );
    getSuggestedFeature();
  };

  const columns = [
    { title: "Sr. No.", dataIndex: "srNo", width: "7%" },
    {
      title: "Date & Time",
      key: "date",
      width: "7%",
      render: (text, record) => (
        <div>
          {dayjs(record?.date).format("DD-MM-YYYY")}
          <br />
          {dayjs(record?.date).format("hh:mm A")}
        </div>
      ),
    },

    {
      title: "Feature Name",
      dataIndex: "name",
      key: "salesManager",
      width: "40%",
    },

    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (text, record) => (
        <Popconfirm
          title="Delete the feature?"
          description="Are you sure you want to delete this feature?"
          onConfirm={() => handleDelete(record._id)}
          onCancel={() => { }}
          okText="Yes"
          cancelText="No"
        >
          <Tag
            color="red"
            style={{
              borderRadius: "2px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              padding: "5px 12px",
              alignItems: "center",
              gap: "5px",
              cursor: "pointer",
              marginLeft: "0px",
              width: 90,
            }}
          >
            <DeleteOutlined /> Delete
          </Tag>
        </Popconfirm>

      ),
    },
  ];

  return (
    <Card
      bodyStyle={{
        padding: "16px 0",
        paddingBottom: "0px",
      }}
      style={{
        border: "1px solid #D9D9D9",
        borderRadius: "4px",
      }}
    >
      <Flex
        justify="space-between"
        style={{ padding: "0px 16px", marginBottom: "16px" }}
      >
        <p style={{ fontSize: "16px", fontWeight: "500" }}>Suggested feature</p>
        <Flex style={{ gap: 10 }}>
          <Button
            type="primary"
            style={{ borderRadius: "2px" }}
            onClick={() => setSuggestedFeatureModal(true)}
          >
            <PlusOutlined /> New Feature
          </Button>
        </Flex>
      </Flex>
      <Table
        dataSource={suggestedFeatures}
        columns={columns}
        pagination={false}
        loading={loading}
      />
      <AddSuggestedFeatureModal
        isOpen={suggestedFeatureModal}
        setIsOpen={setSuggestedFeatureModal}
        getSuggestedFeatures={getSuggestedFeature}
      />
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token,
  };
};

export default connect(mapStateToProps)(SuggestedFeatureTable);
