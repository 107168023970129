import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import LoginForm from "./Login/LoginForm";
import PrivateRoute from "./Login/PrivateRoute";
import InitialScreen from "./components/InitialScreen";
import Dashboard from "./components/Dashboard/Dashboard";
import Blogs from "./components/Blogs/Blogs";
import BulkMessage from "./components/Messages/BulkMessage";
import EditBlog from "./components/Blogs/EditBlog";
import Subscription from "./components/Subscription/Subscription";
import AddBlog from "./components/Blogs/AddBlog";
import axios from "axios";
import { currentUser } from "./Redux/UserAuth/authActions";
import { message } from "antd";
import { connect } from "react-redux";
import Loader from "./components/Loader/Loader";
import { CURRENT_USER_FAILURE } from "./Redux/UserAuth/authTypes";
import UserRoute from "./Utils/UserRoute";
import SeoRoute from "./Utils/SeoRoute";
import AdminRoute from "./Utils/AdminRoute";
import ManualPayment from "./components/ManualPayment/ManualPayment";
import Ticket from "./components/Ticket/Ticket";
import UpdateHistory from "./components/UpdateHistory/UpdateHistory";
import Feature from "./components/Features/Feature";
import LeadTracking from "./components/LeadTracking/LeadTracking";
import RazorpayIntegration from "./components/Razorpay/RazorpayIntegration";
import SmartReport from "./components/SmartReport/SmartReport";
import Payment from "./components/Payment/Payment";
import TestTransfer from "./components/TestTransfer/TestTransfer";
import LeaderBoard from "./components/Ticket/LeaderBoard"; 

function App({ currentUser, loading, dispatch }) {
  useEffect(() => {
    const token = localStorage.getItem("tokenv2");
    if (token) {
      localStorage.clear();
      window.location.href = "/login";
    }
    if (localStorage.getItem("tokenv3")) currentUser();
    else {
      dispatch({
        type: CURRENT_USER_FAILURE,
      });
    }
  });

  if (loading) {
    return <Loader />;
  }
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<InitialScreen />} exact />
          <Route element={<PrivateRoute />}>
            {/* User Route */}
            <Route element={<UserRoute />}>
              <Route path="/dashboard" element={<Dashboard />} exact />
              <Route path="/ticket" element={<Ticket />} exact />
              <Route path="/leaderboard" element={<LeaderBoard />} exact />
              <Route path="/update-history" element={<UpdateHistory />} exact />
              <Route path="/ongoing-feature" element={<Feature />} exact />
              <Route path="/lead-tracking" element={<LeadTracking />} exact />
              <Route
                path="/razorpay-integration"
                element={<RazorpayIntegration />}
                exact
              />
              <Route path="/smart-report" element={<SmartReport />} exact />
              <Route path="/payment" element={<Payment />} exact />
            </Route>
            {/* End User Route */}

            {/* Seo Route */}
            <Route element={<SeoRoute />}>
              <Route path="/blogs" element={<Blogs />} exact />
              <Route path="/blogs/:slug" element={<EditBlog />} exact />
              <Route path="/new-blog" element={<AddBlog />} exact />
            </Route>
            {/* End Seo Route */}

            {/* Admin Route */}
            <Route element={<AdminRoute />}>
              <Route path="/bulk-message" element={<BulkMessage />} exact />
              <Route path="/subscription" element={<Subscription />} exact />
              <Route path="/manual-payment" element={<ManualPayment />} exact />
            </Route>
            {/* End Admin Route */}
          </Route>
          <Route path="/login" element={<LoginForm />} exact />
          <Route path="*" element={<Navigate to="/login" />} />
          <Route path="/test-transfer" element={<TestTransfer/>} />
        </Routes>
      </Router>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    currentUser: () => dispatch(currentUser()),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
